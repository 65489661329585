import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import BEMHelper from "react-bem-helper";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderSolidColor from "../modules/headers/header-solid-color";

import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";
import HubspotForm from "../components/hubsport-form";

import { richTextConfig } from "../rich-text-config/rich-text-config";

import articleFallbackImg from "../assets/img/patterns/article-fallback.png";

const bem = new BEMHelper({
  name: "fourohfour",
});

const FourOhFour = ({ data, location, pageContext }) => {
  // const {
  //   contentfulTemplateListingPage: page,
  //   allContentfulTemplateInsight: insights,
  // } = data;

  // const metaTitle = page.pageTitle ? page.pageTitle : null;
  // const metaDesc = page.seoPageDescription
  //   ? page.seoPageDescription.seoPageDescription
  //   : null;

  // const insightSlugBuilder = (type, slug) => {
  //   if (type === "Newsroom") {
  //     return `/news/${slug}`;
  //   } else {
  //     return `${slug}`;
  //   }
  // };

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  return (
    <Layout>
      <SEO title={"Page not found"} description={""} url={location.href} />
      <HeaderSolidColor headerTitle={"404 Page Not Found"} />
      <div {...bem()}>
        <div {...bem("main-content-wrapper")}>
          <article {...bem("body")}>
            <div {...bem("body-copy-wrapper")}>
              <p>
                The page you were looking for could not be found. Visit the{" "}
                <Link to={"/insights"}>UnitedLex Insights page</Link>, or return
                to the <Link to={"/"}>homepage.</Link>
              </p>
            </div>
          </article>
          <aside {...bem("aside")}></aside>
        </div>
      </div>
    </Layout>
  );
};

export default FourOhFour;
